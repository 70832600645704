var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home d-flex"},[_c('v-container',{staticClass:"d-flex flex-grow-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-grow-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 pb-2",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left v-card-custom-title-left"},[_vm._v(" Symbol Mapping ")]),_c('div',{staticClass:"text-right v-card-custom-title-right"},[_c('v-menu',{attrs:{"offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.selectedLp)+" ")])]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.lpList),function(item,index){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.changeLp(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.lp_name.toUpperCase()))])],1)}),1)],1),_vm._v(" "),_c('v-btn',{attrs:{"small":"","color":"default","loading":_vm.loading},on:{"click":_vm.refreshMapping}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]),_c('v-data-table',{staticClass:"flex-grow-1",attrs:{"dense":"","headers":_vm.symbolMapHeader,"items":_vm.filteredData,"loading":_vm.loading,"loading-text":"Loading... Please wait","items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"header.api_code",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.onSearch('apiCodeSearch')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"apiCodeSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter the search term","single-line":"","hide-details":""},model:{value:(_vm.apiSearch),callback:function ($$v) {_vm.apiSearch=$$v},expression:"apiSearch"}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.apiSearch = ''}}},[_vm._v(" Clean ")])],1)])]}},{key:"header.pk",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.onSearch('pkSearch')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")])]}}],null,true)},[_c('div',{staticStyle:{"background-color":"#1e1e1e","width":"280px"}},[_c('v-text-field',{ref:"pkSearch",staticClass:"px-4",staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Enter the search term","single-line":"","hide-details":""},model:{value:(_vm.pkSearch),callback:function ($$v) {_vm.pkSearch=$$v},expression:"pkSearch"}}),_c('v-btn',{staticClass:"ml-2 mb-2",staticStyle:{"padding":"6px"},attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.pkSearch = ''}}},[_vm._v(" Clean ")])],1)])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"dense":""},on:{"click":function($event){return _vm.toggleDialog(item)}}},[_vm._v("mdi-pen")]),_vm._v(" "),_c('v-icon',{attrs:{"dense":""},on:{"click":function($event){_vm.dialogRemove.visible = true;
                                    _vm.removeData = [item];}}},[_vm._v("mdi-delete")])]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":_vm.dialogMapping.fullscreen,"width":"1200","hide-overlay":true,"persistent":true},model:{value:(_vm.dialogMapping.visible),callback:function ($$v) {_vm.$set(_vm.dialogMapping, "visible", $$v)},expression:"dialogMapping.visible"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(_vm._s(_vm.dialogMapping.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogMapping.fullscreen = !_vm.dialogMapping.fullscreen}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogMapping.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-form',[_c('v-container',_vm._l((Object.keys(
                            _vm.dialogMapping.symbolMapInfo
                        )),function(data){return _c('v-row',{key:data},[_c('v-col',{attrs:{"cols":"3"}},[_c('label',{staticStyle:{"display":"flex","align-items":"center","height":"100%"}},[_vm._v(" "+_vm._s(_vm.updateTitle[data])+" ")])]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"readonly":data == 'id' ||
                                    data == 'Symbol' ||
                                    data == 'Server Name',"required":"","placeholder":'Please enter a new value for ' +
                                    data.replaceAll('_', ' ')},model:{value:(_vm.dialogMapping.symbolMapInfo[data]),callback:function ($$v) {_vm.$set(_vm.dialogMapping.symbolMapInfo, data, $$v)},expression:"dialogMapping.symbolMapInfo[data]"}})],1)],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialogMapping.visible = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.updateMapping()}}},[_c('span',[_vm._v("Update")])])],1)],1)],1),_c('batch-add-symbol-map-dialog',_vm._b({ref:"batchDialog",attrs:{"selected-lp-id":_vm.selectedLpId}},'batch-add-symbol-map-dialog',_vm.$attrs,false)),_c('v-dialog',{attrs:{"fullscreen":_vm.dialogRemove.fullscreen,"width":"1300","hide-overlay":true,"persistent":true},model:{value:(_vm.dialogRemove.visible),callback:function ($$v) {_vm.$set(_vm.dialogRemove, "visible", $$v)},expression:"dialogRemove.visible"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-app-bar',{staticStyle:{"background":"rgb(26, 57, 88)"},attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h6 white--text pl-0"},[_vm._v(_vm._s(_vm.dialogRemove.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogRemove.fullscreen = !_vm.dialogRemove.fullscreen}}},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.dialogRemove.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}}),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.symbolMapHeader.filter(
                                    function (item) { return item.value !== 'action'; }
                                ),"items":_vm.removeData,"item-key":"id","loading":_vm.loading,"items-per-page":-1,"id":"home-table","hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){_vm.dialogRemove.visible = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"red darken-1","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDeletion()}}},[_c('span',[_vm._v("Confirm")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }