var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home d-flex"},[_c('v-container',{staticClass:"d-flex flex-grow-1",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-grow-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"12","md":"12","xs":"12","cols":"12"}},[_c('v-card',{staticClass:"d-flex flex-column",staticStyle:{"height":"100%"},attrs:{"outlined":"","id":"positionRecTable"}},[_c('v-card-title',{staticClass:"pt-2 pb-2",staticStyle:{"border-bottom":"1px solid #404040"}},[_c('div',{staticClass:"text-left v-card-custom-title-left",attrs:{"id":"positionRecLastCheck"}},[_vm._v(" Position Rec - Last Check: "+_vm._s(_vm.lastedCheckingTime)+" ")]),_c('div',{staticClass:"text-right v-card-custom-title-right"},[_c('v-btn',{attrs:{"small":"","color":"default","loading":_vm.loading,"id":"positionRecRefresh"},on:{"click":_vm.refreshRec}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"lg":"6","md":"6","xs":"12"}},[_c('h4',{staticStyle:{"padding":"15px"}},[_vm._v(" Symbols with break ")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.recHeader,"items":_vm.recData.filter(
                                        function (item) { return item.break === 'Yes'; }
                                    ),"id":"symbolWithBreakTable","loading":_vm.loading,"loading-text":"Loading... Please wait","items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.vol_lp",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.vol_lp.toFixed(2) ))+" ")]}},{key:"item.vol_pk",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.vol_pk.toFixed(2) ))+" ")]}},{key:"item.diff",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(Math.abs(item.diff) < 0.01 ? 0 : _vm.numberWithCommas( item.diff.toFixed(2) ))+" ")])]}}])})],1),_c('v-col',{attrs:{"lg":"6","md":"6","xs":"12"}},[_c('h4',{staticStyle:{"padding":"15px"}},[_vm._v(" Symbols without break ")]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.recHeader,"items":_vm.recData.filter(
                                        function (item) { return item.break === 'No'; }
                                    ),"id":"symbolWithoutBreakTable","loading":_vm.loading,"loading-text":"Loading... Please wait","items-per-page":-1,"height":_vm.tableHeight,"hide-default-footer":true,"fixed-header":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.vol_lp",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.vol_lp.toFixed(2) ))+" ")]}},{key:"item.vol_pk",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas( item.vol_pk.toFixed(2) ))+" ")]}},{key:"item.diff",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.diff) < 0.01 ? 0 : _vm.numberWithCommas( item.diff.toFixed(2) ))+" ")]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }