<template>
    <div class="home d-flex">
        <v-container fluid class="d-flex flex-grow-1">
            <v-row dense class="d-flex flex-grow-1">
                <v-col lg="12" md="12" xs="12" cols="12">
                    <v-card
                        outlined
                        class="d-flex flex-column"
                        style="height: 100%"
                        id="positionRecTable"
                    >
                        <v-card-title
                            style="border-bottom: 1px solid #404040"
                            class="pt-2 pb-2"
                        >
                            <div
                                class="text-left v-card-custom-title-left"
                                id="positionRecLastCheck"
                            >
                                Position Rec - Last Check:
                                {{ lastedCheckingTime }}
                            </div>
                            <div class="text-right v-card-custom-title-right">
                                <v-btn
                                    small
                                    color="default"
                                    :loading="loading"
                                    @click="refreshRec"
                                    id="positionRecRefresh"
                                    ><v-icon>mdi-refresh</v-icon></v-btn
                                >
                            </div>
                        </v-card-title>

                        <v-row dense>
                            <v-col lg="6" md="6" xs="12">
                                <h4 style="padding: 15px">
                                    Symbols with break
                                </h4>
                                <v-data-table
                                    dense
                                    :headers="recHeader"
                                    :items="
                                        recData.filter(
                                            (item) => item.break === 'Yes'
                                        )
                                    "
                                    id="symbolWithBreakTable"
                                    :loading="loading"
                                    loading-text="Loading... Please wait"
                                    :items-per-page="-1"
                                    :height="tableHeight"
                                    :hide-default-footer="true"
                                    fixed-header
                                    :header-props="{ sortIcon: null }"
                                >
                                    <template v-slot:item.vol_lp="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.vol_lp.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.vol_pk="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.vol_pk.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.diff="{ item }">
                                        <div style="color: red">
                                            {{
                                                Math.abs(item.diff) < 0.01
                                                    ? 0
                                                    : numberWithCommas(
                                                          item.diff.toFixed(2)
                                                      )
                                            }}
                                        </div>
                                    </template>
                                </v-data-table> </v-col
                            ><v-col lg="6" md="6" xs="12">
                                <h4 style="padding: 15px">
                                    Symbols without break
                                </h4>
                                <v-data-table
                                    dense
                                    :headers="recHeader"
                                    :items="
                                        recData.filter(
                                            (item) => item.break === 'No'
                                        )
                                    "
                                    id="symbolWithoutBreakTable"
                                    :loading="loading"
                                    loading-text="Loading... Please wait"
                                    :items-per-page="-1"
                                    :height="tableHeight"
                                    :hide-default-footer="true"
                                    fixed-header
                                    :header-props="{ sortIcon: null }"
                                >
                                    <template v-slot:item.vol_lp="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.vol_lp.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.vol_pk="{ item }">
                                        {{
                                            numberWithCommas(
                                                item.vol_pk.toFixed(2)
                                            )
                                        }}
                                    </template>
                                    <template v-slot:item.diff="{ item }">
                                        {{
                                            Math.abs(item.diff) < 0.01
                                                ? 0
                                                : numberWithCommas(
                                                      item.diff.toFixed(2)
                                                  )
                                        }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";

export default {
    mixins: [permission, helper, snackbar],
    props: {
        tableHeight: Number,
    },
    data() {
        return {};
    },
    watch: {},
    computed: {
        ...mapState("LP", ["recData", "recHeader", "loading"]),
        lastedCheckingTime() {
            if (!!this.recData && this.recData.length) {
                return this.recData[0]?.updated_at;
            } else {
                return null;
            }
        },
    },
    methods: {
        ...mapActions("LP", ["getLPPositionRecAction"]),
        /**
         * Refresh Position Rec Data
         * @return  {[type]}  [return description]
         */
        refreshRec() {
            this.getLPPositionRecAction();
        },
    },
    mounted() {
        this.getLPPositionRecAction();
    },
};
</script>
